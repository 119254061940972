<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="addWarehouseUnitModal"
    :title="$t(type) + ' ' +  $t($route.params.type)"
    :buttons-hidden="true" autocomplete="nofill">
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="barcode" :label-placeholder="$t('Barcode') + '*'" v-model="warehouseUnit.barcode" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('barcode')">{{ errors.first('barcode') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="aisle sign" :label-placeholder="$t('Aisle Sign') + '*'" v-model="warehouseUnit.aisle_sign" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('aisle sign')">{{ errors.first('aisle sign') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required|numeric|min_value:1'" name="capacity" :label-placeholder="$t('Capacity') + '*'" v-model="warehouseUnit.capacity" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('capacity')">{{ errors.first('capacity') }}</span>
        </div>
        <div class="vx-col w-1/2" v-if="$route.params.type === 'bins'">
          <v-select class="mt-8 w-full" name="to warehouse" v-model="toWarehouse" v-validate="'required'" 
          :placeholder="$t('To Warehouse') + '*'" label="name" :options="warehouses"/>
          <span class="text-danger text-sm" v-show="errors.has('to warehouse')">{{ errors.first('to warehouse') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button v-if="type === 'Add'" @click="addWarehouseUnit" class="active-btn btn">{{ $t('Add') }}</button>
        <button v-else @click="updateWarehouseUnit" class="active-btn btn">{{ $t('Save') }}</button>
      </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'

export default {
  props: ['warehouses', 'warehouseDic', 'addWarehouseUnitModal', 'type', 'warehouseUnit'],
  data () {
    return {
      warehousesDic: {},
      toWarehouse: []
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  watch: {
    type () {
      if (this.type === 'Edit' && this.$route.params.type === 'bins') {
        this.toWarehouse = []
        this.warehouseUnit.to_warehouse = this.toWarehouse.push({
          id: this.warehouseUnit.to_warehouse,
          name: this.warehouseDic[this.warehouseUnit.to_warehouse].name
        })
      } else {
        this.toWarehouse = []
      }
    }
  },
  methods: {
    closeModal () {
      this.$validator.pause()
      this.$emit('addWarehouseUnitModal', false)
      this.$emit('loadWarehousesUnits')
      this.$emit('type', '')
    },
    addWarehouseUnit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('toWarehouse', this.toWarehouse)
          this.$emit('addWarehouseUnit')
          this.closeModal()
        }
      })
    },
    updateWarehouseUnit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('toWarehouse', this.toWarehouse)
          this.$emit('updateWarehouseUnit')
          this.closeModal()
        }
      })
    }
  }
}
</script>